.react-card-flip {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2px;
}

.side-two_light {
    background-color: #ced4da;
}

.side-two_dark {
    background-color: #2b3595;
}

.side-one_light {
    background-color: #0d7bff;
}

.side-one_dark {
    background-color: #e14594;
}

.puzzle-piece {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.isClickable {
    cursor: pointer;
}
