/* using node-style package resolution in a CSS file:  */
@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';

.App {
    text-align: center;
}

.med-input-form {
    margin-bottom: 10px;
}

.root-container {
    width: 1000px;
    margin: auto;
}

@media (max-width: 1000px) {
    .root-container {
        width: 90%;
    }
}

.root-container > .navbar {
    padding-left: 0px;
    padding-right: 0px;
}

/* start of experimentation for form centering */
.narrow-form {
    display: table-cell;
    vertical-align: middle;
}

.narrow-form-form {
    display: inline-block;
}

.vertical-center {
    margin: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.horizontal-center {
    position: fixed;
    left: 37%;
}

.vertical-center {
    position: fixed;
    top: 50%;
}

.centered-form {
    width: 250px;
}

@media (max-width: 600px) {
    .horizontal-center {
        position: static;
        left: auto;
        width: 100%;
    }

    .vertical-center {
        width: 90%;
        top: 57%;
    }

    .centered-form {
        width: 100%;
    }
}
/* end of experimentation for form centering */

/* navlinks */
.navlinks_light {
    color: #959595;
}

.navlinks_dark {
    color: #e14594;
    font-weight: 800;
}

/* btn-primary */
.btn-primary {
    background-color: #2b3595;
    border-color: #2b3595;
    font-weight: 800;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    background-color: #7045af;
    border-color: #7045af;
}

.btn-primary:hover {
    background-color: #222a77;
    border-color: #222a77;
}

/* btn-success */
.btn-success {
    background-color: #e14594;
    border-color: #e14594;
    font-weight: 800;
}
.btn-success:hover {
    background-color: #b43776;
    border-color: #b43776;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
    background-color: #e14594;
    border-color: #e14594;
}

.btn-link {
    font-weight: 800;
}

.h4 {
    font-weight: 800;
}

.swal-icon--success::before {
    background-color: #182952;
}

.swal-icon--success::after {
    background-color: #182952;
}

.swal-icon {
    background-color: #182952;
}
.swal-modal {
    background-color: #182952;
    border-color: #7045af;
    border-width: 3px;
}
.swal-icon--success__ring {
    border-color: white;
}

.swal-icon--success__line {
    background-color: white;
}

.swal-icon--success__hide-corners {
    background-color: #182952;
}

.swal-button {
    background-color: #2b3595;
    border-color: #2b3595;
    font-weight: 800;
}
.swal-button:hover {
    background-color: #7045af;
    border-color: #7045af;
}
.swal-button:not([disabled]):hover {
    background-color: #7045af;
    border-color: #7045af;
}

.swal-overlay--show-modal .swal-modal {
    border-color: #7045af;
    border-width: 4px;
    border-style: solid;
}

.swal-title {
    color: white;
    font-weight: 800;
}
.swal-text {
    color: white;
    font-weight: 800;
}

.swal-button:focus {
    box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    background-color: #7045af;
    border-color: #7045af;
}

/* alerts */
tr.alert-warning_dark > th {
    background-color: #e14594;
    color: white;
    border-color: #7045af;
    border-width: 3px;
}

tr.table-body_dark > td {
    background-color: #2b3595;
    color: white;
    font-weight: 800;
    border-color: #7045af;
    border-width: 3px;
}

.alert-warning_dark {
    background-color: #e14594;
    color: white;
    border-color: #7045af;
    border-width: 3px;
    font-weight: 800;
}

/* Underline From Left */
.hvr-underline-from-left {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
}
.hvr-underline-from-left:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: #7045af;
    height: 4px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.hvr-underline-from-left:hover:before,
.hvr-underline-from-left:focus:before,
.hvr-underline-from-left:active:before {
    right: 0;
}

.modal-content {
    background-color: #182952;
}

.modal-content > div {
    color: white;
    font-weight: 800;
}

.modal-header {
    border-bottom: 3px solid #7045af;
}

.modal-footer {
    border-top: 3px solid #7045af;
}

.close {
    color: white;
    opacity: 1;
}
.close:hover {
    color: #e5e5e5;
    opacity: 1;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
    background-color: #7045af;
    border-color: #7045af;
}
