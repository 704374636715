@font-face {
    font-family: 'BreeSerif-Regular';
    src: local('BreeSerif-Regular'), url(./fonts/BreeSerif-Regular.ttf) format('.ttf');
}

body {
    margin: 0;
    font-family: 'BreeSerif-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
